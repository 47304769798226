import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {InfoActions, RiderActions, RidesActions} from '../actions';
import {catchError, debounceTime, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';
import {RiderSelectors, RidesSelectors} from '../selectors';
import {AppState} from '../state';
import {Store} from '@ngrx/store';
import {RiderService} from '@carol-nx/services';
import {buildPagingObject} from '@carol-nx/utils';
import {RideHealthInfoWithHR, RideTypes} from '@carol-nx/data';
import {EffectsMain} from './effects-main';

@Injectable()
export class RidesEffects extends EffectsMain {

  GetRideInfoByRideIdAndRiderId$ = this.createEffectWithLatestSwitchMap(RidesActions.GetRideInfoByRideIdAndRiderId,
    (action, riderId) => this.riderService.getRideDataByIdAndRiderId(action.rideId, riderId)
      .pipe(
        switchMap(resp => {
          // this.dialogService.closeModal();
          const action1 = RidesActions.SetRideInfoByRideIdAndRiderId({ rideData: resp });
          return of(action1);
        }),
        catchError(errMessage => {
          // this.dialogService.closeModal();
          return of(InfoActions.SetCommonError({ title: 'Error', message: 'Something went wrong.' }));
        })
      )
  );

  deleteRide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidesActions.DeleteRide),
      withLatestFrom(this.store.select(RiderSelectors.selectRiderId),
        this.store.select(RidesSelectors.selectRidesByType),
        this.store.select(RidesSelectors.selectRidesPagingByType)),
      mergeMap(([action, riderId, rides, paging]) => this.riderService.deleteRideById(riderId, action.rideId)
        .pipe(
          switchMap(resp => {
            let action1 = null;
            if (rides && rides.length !== paging.totalElements) {
              action1 = RidesActions.GetRidesByType({
                pagingData: {
                  page: rides.length.toString(),
                  size: 1
                }
              });
            }
            const action2 = RidesActions.DeleteRideSuccess({ rideId: action.rideId });
            return action1 ? of(action1, action2) : of(action2);
          }),
          catchError(err => of(InfoActions.SetCommonError({
            title: 'Error',
            message: 'Could not delete the ride. Please try again later'
          })))
        )
      )
    )
  );

  GetRidesByType$ = this.createEffectSwitchMap(RidesActions.GetRidesByType, (action, riderId) =>
    this.store.select(RidesSelectors.selectRideType).pipe(
      filter(currentRideType => !!currentRideType),
      debounceTime(0),
      switchMap((currentRideType) => this.riderService.getRidesByType(
          riderId, currentRideType, action.pagingData as HttpParams
        ).pipe(
          switchMap(resp => {
            const pagingDataObject = buildPagingObject(resp);
            const action1 = RidesActions.SetRidesByType({
              ridePeriodicElements: resp.content,
              rideType: currentRideType
            });
            const action2 = RidesActions.SetRidesPagingDataByType({
              pagingData: pagingDataObject,
              rideType: currentRideType
            });
            // this.dialogService.closeModal();
            return of(action1, action2);
          }),
          catchError(errMessage => {
            // this.dialogService.closeModal();
            return of(InfoActions.SetCommonError({ title: 'Error', message: 'Something went wrong.' }));
          })
        )
      )
    )
  );

  GetStatsArrByRideId$ = this.createEffectWithLatestSwitchMap(RidesActions.GetStatsArrByRideId,
    (action, riderId) => {
      const statsArrObservables = [];
      action.statNames.forEach(statName => {
        switch (statName) {
          case 'habit-score':
            statsArrObservables.push(of({ habitScorePointer: action.ride.start }));
            break;
          case 'dates':
            statsArrObservables.push(of({ calendarPointer: action.ride.start }));
            break;
          case 'rides-per-week':
            statsArrObservables.push(this.riderService.getRidesPerWeekCount(riderId));
            break;
          case 'duration':
            const durationObservable = new Observable<Object>(subscriber => {
              subscriber.next({
                duration: action.ride.type && action.ride.type === RideTypes.EnduranceRides ?
                  action.ride.openStageDuration : action.ride.duration
              });
              subscriber.complete();
            });
            statsArrObservables.push(durationObservable);
            break;
          default:
            statsArrObservables.push(this.riderService.getStatByNameAndRideId(riderId, action.ride.id, statName));
        }
      });
      return forkJoin(statsArrObservables).pipe(switchMap(resp => {
          const respCopy = Object.assign([], resp);
          const actions = [];
          actions.push(RidesActions.SetStatsArrByRideId({ statsArr: respCopy }));
          const datesIndex = action.statNames.findIndex(f => f === 'dates');
          if (datesIndex >= 0) {
            actions.push(RiderActions.SetRiderMonthlyActivity({ datesWithRides: respCopy[datesIndex].datesWithRides }));
          }
          const perWeekIndex = action.statNames.findIndex(f => f === 'rides-per-week');
          if (perWeekIndex >= 0) {
            respCopy[perWeekIndex].last = respCopy[perWeekIndex].ridesPerWeekString;
            respCopy[perWeekIndex].lastFull = respCopy[perWeekIndex].ridesPerWeek;
          }
          return actions;
        }),
        catchError(errMessage => {
          return of(InfoActions.SetCommonError({ title: 'Error', message: 'Something went wrong.' }));
        })
      );
    }
  );

  GetLastRide$ = this.createEffectSwitchMap(RidesActions.GetLastRideByRiderId,
    (action, riderId) => this.riderService.getLastRideByRiderId(riderId)
      .pipe(
        map(rideData => RidesActions.SetLastRide({ rideData })),
        catchError(errMessage => of(InfoActions.SetCommonError({ title: 'Error', message: 'Something went wrong.' })))
      ));

  GetRideHealthInfoWithHR$ = this.createEffectSwitchMap(RidesActions.GetRideHealthInfoWithHR, (action: {
    date?: number,
    limit?: number
  }, riderId) => this.riderService.getRideHealthInfoWithHR(riderId, action.date, action.limit)
    .pipe(
      map(data => {
        return RidesActions.SetRideHealthInfoWithHR({rideHealthInfoWithHR: data as RideHealthInfoWithHR[]})
      }),
      catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: JSON.stringify(err)})))
    ));

  constructor(protected actions$: Actions,
              protected store: Store<AppState>,
              private riderService: RiderService) {
    super(actions$, store, null);
  }
}

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {LINE_BACKGROUND_COLOR, MAIN_ACCENT_COLOR, SECOND_TEXT_COLOR, TrendValue} from '@carol-nx/data';
import {Chart} from 'angular-highcharts';
import {ChartOptions, PlotOptions, TooltipOptions, XAxisOptions, YAxisOptions} from 'highcharts';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements OnInit {
  private readonly desktopSafari: boolean;

  @Input()
  set data(data: TrendValue[]) {
    this.dataTrend = data;
  }

  @Input()
  private lineColor = MAIN_ACCENT_COLOR;
  private chartInstance: Chart;
  private dataTrend: TrendValue[];

  constructor() {
    this.desktopSafari = this.isDesktopSafari();
  }

  get data() {
    return this.dataTrend;
  }

  ngOnInit() {
    if (this.data) {
      let clientWidth = this.findClientWidth(document.getElementsByClassName('chart'));
      this.initSingleLineChart({newWidth: clientWidth});
    }
  }

  private findClientWidth(charts: HTMLCollectionOf<Element>): number {
    for (let i = 0; i < charts.length; i++) {
      if (charts[i].clientWidth)
        return charts[i].clientWidth;
    }
    return 0;
  }

  public getChart() {
    return this.chartInstance;
  }

  public initSingleLineChart($event: { newWidth: number; }) {
    if (!this.data)
      return;
    const currentFontSize = Number(window.getComputedStyle(document.body).getPropertyValue('font-size').match(/\d+/)[0]);
    const labelWidth = 59 * currentFontSize / 24;
    const multiplier = Math.round(currentFontSize / 18);
    const tooltipOptions: TooltipOptions = {
      borderRadius: 6 * multiplier,
      hideDelay: 0,
      borderWidth: multiplier,
      borderColor: 'rgba(0,0,0,.1)',
      backgroundColor: '#ffffff',
      shadow: true,
      shared: false,
      xDateFormat: '%d %b %Y %H:%M',
      pointFormat: '{point.y:.0f}<br/>',
      headerFormat: '<span style="font-size: 0.6rem">{point.key}</span><br/>',
      style: {
        fontSize: '0.8rem'
      }
    };
    const tickLimit = Math.floor(($event.newWidth * 0.9) / labelWidth);
    const monthInMillis = 1000 * 60 * 60 * 24 * 30;
    let minTickInterval: number;
    const times = this.data.map(d => d.time);
    const timeInterval = times[times.length - 1] - times[0];
    if (timeInterval) {
      if (timeInterval / monthInMillis > tickLimit) {
        const yearInMillis = 1000 * 60 * 60 * 24 * 365;
        if (timeInterval / yearInMillis > 6) {//fixed 6 years
          minTickInterval = yearInMillis;
          const multiplier = Math.ceil(timeInterval / yearInMillis / tickLimit);
          minTickInterval = minTickInterval * multiplier;
        } else {
          const multiplier = Math.ceil(timeInterval / monthInMillis / tickLimit) + 1;
          if (multiplier >= 8) {
            minTickInterval = yearInMillis;
          } else if (timeInterval / monthInMillis > tickLimit) {
            minTickInterval = monthInMillis * multiplier;
          } else {
            minTickInterval = monthInMillis;
          }
        }
      } else {
        minTickInterval = monthInMillis;
      }
    }
    const xAxisOptions: XAxisOptions = {
      type: 'datetime',
      lineColor: LINE_BACKGROUND_COLOR,
      lineWidth: multiplier,
      minTickInterval,
      tickLength: 10 * multiplier,
      tickWidth: multiplier,
      tickColor: LINE_BACKGROUND_COLOR,
      tickPosition: 'inside',
      labels: {
        format: '{value:%b-%y}',
        style: {
          color: SECOND_TEXT_COLOR,
          fontSize: '0.8rem',
          whiteSpace: 'nowrap'
        }
      }
    };
    const yAxisOptions: YAxisOptions = {
      gridLineColor: '#e0e0e025',
      gridLineWidth: multiplier,
      tickAmount: 4,
      lineColor: '#e0e0e025',
      lineWidth: multiplier,
      min: 0,
      title: {
        text: ''
      },
      labels: {
        style: {
          color: SECOND_TEXT_COLOR,
          fontSize: '0.8rem'
        }
      },
      showEmpty: false
    };
    const plotOptions: PlotOptions = {
      series: {
        lineWidth: multiplier,
        marker: {
          radius: 0
        }
      }
    };
    const marginBottom = Math.round(34 / 24 * currentFontSize);
    const marginTop = Math.round(7 / 24 * currentFontSize);
    const chartOptions: ChartOptions = {
      type: 'spline',
      panKey: 'shift',
      zooming: { type: 'x' },
      spacingRight: 0,
      marginBottom,
      marginTop,
      backgroundColor: 'transparent',
      style: {
        color: 'rgba(0, 0, 0, 1)',
        fontSize: '1rem'
      }
    };
    let data: TrendValue[];
    if (this.data.length > 100 && this.desktopSafari) {
      data = JSON.parse(JSON.stringify(this.data));
      for (let j = 0; j < 8; j++) {//do 7 rounds to spread out very dense data
        for (let i = 1; i < data.length; i++) {
          const d = data[i];
          const d0 = data[i - 1];
          if (d.time - d0.time < 3600 * 1000 && i + 1 < data.length) {//if less than 1 hour
            d.time = (d0.time + data[i + 1].time) / 2;
          }
        }
      }
    } else {
      data = this.data;
    }
    this.chartInstance = new Chart({
      accessibility: { enabled: false },
      chart: {
        ...chartOptions
      },
      title: {
        text: ''
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      xAxis: {
        ...xAxisOptions
      },
      yAxis: {
        ...yAxisOptions
      },
      tooltip: {
        ...tooltipOptions
      },
      plotOptions: {
        ...plotOptions
      },
      legend: {
        enabled: false
      },
      series: [{
        clip: false,
        color: {},
        lineWidth: 3 * multiplier,
        lineColor: this.lineColor,
        data: data.map(el => ([el.time, el.value]))
      }]
    } as Highcharts.Options);
  }

  private isDesktopSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
  }
}

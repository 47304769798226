import {
  ExtendedRideData,
  PowerStatsChartModel,
  PowerStatsModel,
  RideHealthInfoWithHR,
  RidePeriodicElement,
  RideTypes,
  TablePagingData
} from "@carol-nx/data";
import {createAction, props} from "@ngrx/store";

export const SetRideType = createAction(
  '[Rides] Set ride type',
  props<{rideType: RideTypes}>()
);

export const SetSelectedRideId = createAction(
  '[Rides] Set selected ride id',
  props<{id: number}>()
);

export const GetLastRideByRiderId = createAction(
  '[Rides] Get last ride by riderId',
  props<{riderId: number}>()
);

export const SetLastRide = createAction(
  '[Rides] Set last ride',
  props<{rideData: ExtendedRideData}>()
);

export const GetRideInfoByRideIdAndRiderId = createAction(
  '[Rides] Get ride info by rideId and riderId',
  props<{rideId: number}>()
);

export const SetRideInfoByRideIdAndRiderId = createAction(
  '[Rides] Set ride info by rideId and riderId',
  props<{rideData: ExtendedRideData}>()
);

export const SetDisplayedStatValuesForRide = createAction(
  '[Rides] Set displayed stat values for ride',
  props<{stats: PowerStatsModel}>()
);

export const DeleteRide = createAction(
  '[Rides] Delete ride by rideId',
  props<{rideId: number}>()
);

export const DeleteRideSuccess = createAction(
  '[Rides] Delete ride by rideId success',
  props<{rideId: number}>()
);

export const GetRidesByType = createAction(
  '[Rides] Get rides ',
  props<{pagingData: TablePagingData, rideType?: RideTypes, riderId?: number}>()
);

export const GetStatsArrByRideId = createAction(
  '[Rides] Get stats data array',
  props<{statNames: string[], ride: RidePeriodicElement}>()
);

export const SetRidesByType = createAction(
  '[Rides] Set rides periodic elements',
  props<{ridePeriodicElements: RidePeriodicElement[], rideType?: RideTypes}>()
);

export const ResetRidesByType = createAction(
  '[Rides] Reset rides periodic elements',
  props<{ridePeriodicElements: RidePeriodicElement[], rideType?: RideTypes}>()
);

export const SetRidesPagingDataByType = createAction(
  '[Rides] Set rides paging data',
  props<{pagingData: TablePagingData, rideType?: RideTypes}>()
);

export const SetStatsArrByRideId = createAction(
  '[Rides] Set stats data array',
  props<{statsArr: PowerStatsChartModel[]}>()
);

export const UnsetAllRides = createAction(
  '[Rides] Unset All Rides',
);

export const GetRideHealthInfoWithHR = createAction(
  '[Rides] Get Ride Health Info',
  props<{ date?: number, limit?: number }>()
);

export const SetRideHealthInfoWithHR = createAction(
  '[Rides] Set Ride Health Info',
  props<{ rideHealthInfoWithHR: RideHealthInfoWithHR[] }>()
);


import {ExtendedRideData, PowerStatsChartModel, PowerStatsModel, RideHealthInfoWithHR, RideTypes} from '@carol-nx/data';

export interface RidesState {
  rides?: {}; // RidePeriodicElement[] by type in property
  ridesPaging?: {}; // TablePagingData by type in property
  rideType?: RideTypes;
  selectedRideId?: number;
  selectedRideExtendData?: ExtendedRideData;
  selectedRideStatValues?: PowerStatsModel;
  statsArray?: (PowerStatsChartModel | string)[];
  lastRide?: ExtendedRideData;
  rideHealthInfoWithHR?: RideHealthInfoWithHR[];
}

// didn't found path of changing last selected ride id
// decided to set it up here from local storage
const savedRideIdParam = JSON.parse(localStorage.getItem('lastVisitedUrlParams'))?.rideId;

export const initialRidesState: RidesState = {
  selectedRideId: savedRideIdParam || null
};

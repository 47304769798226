import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import {
  ChartType,
  GaugeChartModel,
  LINE_BACKGROUND_COLOR,
  MAIN_LINE_COLOR,
  MAIN_TEXT_COLOR,
  NOT_VALID_SUBSCRIPTION_COLOR,
  SECOND_TEXT_COLOR
} from '@carol-nx/data';
import * as d3Select from 'd3-selection';
import * as d3Shape from 'd3-shape';
import * as d3Interpolate from 'd3-interpolate';
import {BehaviorSubject} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {GaucheResizeService} from '@carol-nx/services';

const MARK_SVG_PATH = 'M0 2H2L2 14 0 14Z';

@UntilDestroy()
@Component({
  selector: 'carol-nx-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GaugeChartComponent implements AfterViewInit {
  @ViewChild('chart', { static: false }) protected chartContainer: ElementRef;
  @ViewChild('holder', { static: true }) protected svgHolder: ElementRef;

  @Input()
  set chartData(data: GaugeChartModel) {
    let modified = { ...data };
    if (modified.vsPeers?.vs && modified.validSubscription) {
      modified.vsPeers.title = 'Vs. Peers';
    }
    this._chartData.next(modified);
  };

  @Input()
  readonly hideBaseValue: boolean;
  @Input()
  readonly hideBestValue: boolean;
  @Input()
  readonly vsPeers: boolean;
  @Input()
  private animationDuration = 0;
  @Input()
  public showZeroValue: boolean;
  @Input()
  public resizeInitAfter = 100;

  public _chartData = new BehaviorSubject<GaugeChartModel>(undefined as GaugeChartModel);
  public initPeers = new BehaviorSubject<boolean>(false);

  private svg: any;
  private margin: { top: number; right: number; bottom: number; left: number; };
  public width: number;

  private size: number;
  private thickness = 10;//todo calc
  private fontSize = 18;//todo get from html

  private mainArc: d3Shape.Arc<any, d3Shape.DefaultArcObject>;
  private foregroundArc;
  private baseValue;
  private baseGroup;
  private bestGroup;
  private bestValue;
  private targetRange;
  private targetGroup;

  private outerRadiusStar: number;
  private innerRadiusStar: number;
  private viewBuildFinished = false;
  private resizeTimeout: number;
  private visibilityTimeout: number;

  constructor(private changeDetectorRef: ChangeDetectorRef, private gaucheResizeService: GaucheResizeService) {
  }

  get arrowBound() {
    const arrowLength = 14 * 1.1;
    const arrowHeight = 7 * 1.1;
    return { length: arrowLength, height: arrowHeight };
  }

  public onResize() {
    if (window) {
      const {width} = this.svgHolder.nativeElement.getBoundingClientRect();
      if (width === 0)
        return;
      this.viewBuildFinished = false;
      this.resetChart();
    }
  }

  public ngAfterViewInit(): void {
    const chartContainerElement = this.chartContainer.nativeElement;
    this.svg = d3Select.select(chartContainerElement);
    this._chartData.pipe(
      filter(data => !!data),
      map(data => {
        if (data && data.validSubscription === false) {
          data.mainColor = NOT_VALID_SUBSCRIPTION_COLOR;
          data.vsPeers = {
            color: NOT_VALID_SUBSCRIPTION_COLOR,
            vsFill: 100
          };
        }
        return data;
      }),
      tap(() => this.resetChart()),
      untilDestroyed(this)
    ).subscribe();

    this.gaucheResizeService.getEvents().pipe(
      filter(size => size !== this.size && !this.viewBuildFinished),
      tap((size) => this.resetChart(false, size)),
      untilDestroyed(this)
    ).subscribe();
  }

  private hasParentDifferentSizeGaucheCharts(): boolean {
    return document.querySelectorAll('.page-wrapper>div>carol-nx-dashboard-chart').length === 5
      && document.querySelectorAll('mat-dialog-container').length === 0;
  }

  private redraw(emitResize: boolean = true, size?: number) {
    this.initChart(emitResize, size);
    this.drawGaugeChart();
    this.update();
    if (!this.hasParentDifferentSizeGaucheCharts()) {
      this.svgHolder.nativeElement.style.visibility = '';
    }
  }

  private resetChart(emitResize: boolean = true, size?: number) {
    if (this.chartContainer) {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.viewBuildFinished = true;
      }, 1000);
      if (this.initPeers.getValue()) {
        this.initPeers.next(false);
        this.changeDetectorRef.detectChanges();
      }
      this.svgHolder.nativeElement.style.visibility = 'hidden';
      if (this.hasParentDifferentSizeGaucheCharts()) {
        if (this.visibilityTimeout) {
          clearTimeout(this.visibilityTimeout);
        }
        this.visibilityTimeout = setTimeout(() => {
          this.svgHolder.nativeElement.style.visibility = '';
        }, 300);
      }
      const chartContainerElement = this.chartContainer.nativeElement;
      const styleFontSize = window.getComputedStyle(chartContainerElement as Element, null).getPropertyValue('font-size');
      this.fontSize = parseFloat(styleFontSize);
      this.svg.html('');
      this.svg.style('width', '0px');
      this.svg.style('height', '0px');
      this.redraw(emitResize, size);
    } else {
      console.warn('no chartContainer!');
    }
  }

  private initChart(emitResize: boolean = true, size?: number) {
    const chartContainerElement = this.chartContainer.nativeElement;
    const { width, height } = this.svgHolder.nativeElement.getBoundingClientRect();

    const percentWidth = 0.4;

    this.size = Math.round(width * percentWidth);//height
    if (this.size > height * 0.85) {
      this.size = Math.round(height * 0.85);
    }
    if (size && size < this.size - 17) {
      this.size = size;
    }

    this.thickness = this.fontSize * 0.3;

    const xwidth = this.size * 2 / percentWidth;
    // svg width is 55% from design when the container is 465px
    const chartContainerWidth = Math.round(percentWidth * xwidth);
    this.svg.style('width', chartContainerWidth + 'px');


    // svg height is 45% from design when the container height is 220px
    const svgHeight = chartContainerWidth / 2;
    this.svg.style('height', svgHeight + 'px');

    chartContainerElement.style.minHeight = svgHeight + 'px';

    this.margin = {
      top: this.svg.style('margin-top').replace('px', '') * 1,
      right: this.svg.style('margin-right').replace('px', '') * 1,
      bottom: this.svg.style('margin-bottom').replace('px', '') * 1,
      left: this.svg.style('margin-left').replace('px', '') * 1
    };

    this.width = this.svg.style('width').replace('px', '') * 1;

    this.svg.append('g').attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    this.outerRadiusStar = this.size / 15;
    this.innerRadiusStar = this.outerRadiusStar * 0.4;

    if (emitResize)
      this.gaucheResizeService.notifyResize(this.size);
    this.initPeers.next(true);
    this.changeDetectorRef.detectChanges();
  }

  private drawGaugeChart() {
    this.mainArc = d3Shape.arc()
      .outerRadius(this.size)
      .innerRadius(this.size - this.thickness)
      .startAngle(-Math.PI / 2);

    const gaugeHeight = this.size;
    const chart = this.svg.append('g')
      .attr('transform', `translate(${this.width / 2}, ${gaugeHeight})`);

    const chData = this._chartData.getValue();

    chart.append('path')
      .datum({
        endAngle: Math.PI / 2
      })
      .attr('class', 'background')
      .style('fill', chData.validSubscription ? LINE_BACKGROUND_COLOR : NOT_VALID_SUBSCRIPTION_COLOR)
      .attr('d', this.mainArc);

    this.foregroundArc = chart.append('path')
      .datum({
        endAngle: -Math.PI / 2
      })
      .style('fill', chData.mainColor || MAIN_LINE_COLOR)
      .attr('d', this.mainArc);

    this.svg.append('g')
      .style('transform', `translate(${this.width / 2}px, ${this.size * 0.3 + this.outerRadiusStar / 2 + this.size / 8 + (this.size * 0.7 - this.size / 8) / 2}px)`)
      .append('text')
      .text(0)
      .attr('val', chData.lastFull || chData.last)
      .attr('val-str', (chData.validSubscription || chData.chartType === ChartType.RidesCount || chData.chartType === ChartType.RidersCount) ? chData.last : 'N/A')
      .attr('prop', 'current')
      .attr('text-anchor', 'middle')
      .attr('class', 'main-value animate')
      .transition()
      .duration(this.animationDuration)
      .call(GaugeChartComponent.textTween);

    if (chData.units) {
      this.svg.append('text')
        .text(chData.units)
        .attr('class', 'units')
        .attr('style', `font-size:0.8rem`)
        .attr('transform', `translate(${this.size}, ${gaugeHeight})`)
        .attr('text-anchor', 'middle');
    }

    if (this.showZeroValue) {
      if (chData.bestFull > 0 || +chData.targetMax > 0) {
        this.drawChartBestData();
      } else if (chData.bestFull == 0 || +chData.targetMax == 0) {
        this.drawChartBestNullData();
      }
    } else {
      if (!this.hideBestValue && (chData.best || chData.targetMax)) {
        this.drawChartBestData();
      }
    }

    if (!this.hideBaseValue && chData.base || chData.target || chData.targetMin) {
      this.drawChartBaseData();
    }

    if (chData.targetMin || chData.targetMax) {
      this.drawChartTargetRangeData();
    }

    if (chData.starCount) {
      this.drawStars(chData.starCount);
    }
  }

  private update() {
    const chData = this._chartData.getValue();
    const { baseFull, bestFull, last, lastFull, arcMax, arcMaxDouble, target, targetMax, targetMin } = chData;

    const currentValue = lastFull || Number(`${last}`.replace(',', ''));
    const arcMaxNeeded = arcMaxDouble !== undefined ? (arcMaxDouble || target * 1.05) : arcMax;

    // this is silly shit. we need sensible numbers
    const divideNum = arcMaxNeeded > 0 ? arcMaxNeeded : (bestFull > 0 ? Math.max(bestFull, lastFull || 0) : currentValue > 0 ? currentValue * 100 : 10000);
    const percentage = ((currentValue ? currentValue : 0) / divideNum * 100);

    const newAngle = percentage / 100 * Math.PI - Math.PI / 2;

    this.foregroundArc.transition()
      .duration(this.animationDuration)
      .attrTween('d', (d: d3Shape.DefaultArcObject) => {
        const interpolate = d3Interpolate.interpolateNumber(d.endAngle, newAngle);
        return (t: number) => {
          d.endAngle = interpolate(t);
          return this.mainArc(d);
        };
      });

    let newBestAngle: number;
    let newBaseAngle: number;

    if (this.bestValue && !this.hideBestValue && bestFull == 0) {
      newBestAngle = (this.baseValue ? 25 : 1) / 100 * Math.PI - Math.PI / 2;
    } else if (this.bestValue && !this.hideBestValue) {
      const value = bestFull || target || targetMax;
      const newPercentage = (value ? value : 0) / divideNum * 100;
      newBestAngle = newPercentage / 100 * Math.PI - Math.PI / 2;
    }

    if (this.baseValue && baseFull !== undefined || target !== undefined || targetMin !== undefined) {
      const value = baseFull || target || targetMin;
      const newPercentage = (value ? value : 0) / divideNum * 100;
      newBaseAngle = newPercentage / 100 * Math.PI - Math.PI / 2;
    }

    if (targetMin !== undefined && targetMax !== undefined) {
      const newPercentage = (targetMin ? targetMin : 0) / divideNum * 100;
      const newTargetMinAngle = newPercentage / 100 * Math.PI - Math.PI / 2;
      const newTargetRangeAngle = [Math.PI / 2, newTargetMinAngle].reduce((a, b) => (a + b)) / 2;
      this.updateTargetRangeValue(newTargetRangeAngle, newBaseAngle, newBestAngle);
    } else if (!!newBaseAngle && !!newBestAngle) { // If both values - checking space between arrows
      const arrowLength = this.arrowBound.length;
      const arrowHeight = this.arrowBound.height;
      const betweenArrows = 2.5; //coefficient of minimum distance between arrows
      let arrowLargerRadius = arrowLength * 1.1 + this.arrowIndent();
      const arrowAngle = 2 * Math.atan((arrowHeight / 2) / arrowLargerRadius);
      const minAngle = betweenArrows * arrowAngle;
      if (Math.abs(newBaseAngle - newBestAngle) < minAngle) {
        const diff = minAngle - Math.abs(newBaseAngle - newBestAngle);
        if (newBaseAngle <= newBestAngle) {
          newBaseAngle -= diff / 2;
          newBestAngle += diff / 2;
        } else {
          newBaseAngle += diff / 2;
          newBestAngle -= diff / 2;
        }
        let correct = 0;
        if (Math.min(newBaseAngle, newBestAngle) < -Math.PI / 2) {
          correct = -Math.PI / 2 - Math.min(newBaseAngle, newBestAngle);
        } else if (Math.max(newBaseAngle, newBestAngle) > Math.PI / 2) {
          correct = Math.PI / 2 - Math.min(newBaseAngle, newBestAngle);
        }
        newBestAngle += correct;
        newBaseAngle += correct;
      }

      this.updateBestValue(newBestAngle);
      this.updateBaseValue(newBaseAngle);
    } else if (newBestAngle != null) {
      this.updateBestValue(newBestAngle);
    } else {
      this.updateBaseValue(newBaseAngle);
    }
  }

  // TODO: make interpolation great again
  private static textTween(transition) {
    transition.tween('text', function() {
      // const toVal = this.getAttribute('val');
      const toValStr = this.getAttribute('val-str');
      const type = this.getAttribute('prop');
      const x = this.getAttribute('x-pos') || 0;

      return () => {

        const baseCard = `<tspan x="0" dy="1.8em">Base</tspan> <tspan x="0" dy="1.2em">${toValStr}</tspan>`;
        const bestCard = `<tspan x="${x}" dy="1.8em">Best</tspan> <tspan x="${x}" dy="1.2em">${toValStr}</tspan>`;
        const targetMinCard = `<tspan x="${x}" dy="1.2em" alignment-baseline="text-after-edge">${toValStr}</tspan>`;
        const targetMaxCard = `<tspan x="${x}" dy="1.2em" alignment-baseline="text-before-edge">${toValStr}</tspan>`;
        const targetRangeCard = `<tspan x="-1em" dy="1.2em">Target</tspan> <tspan x="-1em" dy="1.2em">Range</tspan>`;
        const targetOneCard = `<tspan x="${x}" dy="1.2em">Target</tspan> <tspan x="1em" dy="1.2em">${toValStr}</tspan>`;

        switch (type) {
          case 'base':
            this.innerHTML = baseCard;
            break;
          case 'best':
            this.innerHTML = bestCard;
            break;
          case 'targetRange':
            this.innerHTML = targetRangeCard;
            break;
          case 'target-min':
            this.innerHTML = targetMinCard;
            break;
          case 'target-max':
            this.innerHTML = targetMaxCard;
            break;
          case 'targetOne':
            this.innerHTML = targetOneCard;
            break;
          default:
            this.innerHTML = toValStr;
            break;
        }
        return this.innerHTML;
      };
    });
  }

  private static calcAngleDegrees(angleRadians: number) {
    return 360 * angleRadians / (2 * Math.PI);
  }

  private drawChartBestData() {
    const chData = this._chartData.getValue();
    const currentValue = chData.bestFull !== undefined ? chData.bestFull : chData.targetMax;
    const currentStrValue = chData.best !== undefined ? chData.best : chData.targetMax;
    this.bestGroup = this.svg.append('g')
      .attr('group-name', 'best')
      .datum({ endAngle: -Math.PI / 2 });

    this.bestValue = this.bestGroup.append('text')
      .text(0)
      .attr('class', 'hint-value animate')
      .attr('text-anchor', 'left')
      .attr('val', currentValue)
      .attr('val-str', currentStrValue)
      .attr('prop', chData.bestFull >= 0 ? 'best' : 'target-max')
      .datum({ endAngle: -Math.PI / 2 });

    this.bestGroup.append('path')
      .attr('d', MARK_SVG_PATH)
      .attr('fill', SECOND_TEXT_COLOR)
      .attr('transform', 'scale(1.1)')
      .datum({ endAngle: -Math.PI / 2 });
  }

  private drawChartBestNullData() {
    const chData = this._chartData.getValue();
    if (!this.hideBestValue && (chData.best || chData.targetMax)) {
      this.bestGroup = this.svg.append('g')
        .attr('group-name', 'best')
        .datum({ endAngle: -Math.PI / 2 });

      this.bestGroup.append('path')
        .attr('d', MARK_SVG_PATH)
        .attr('fill', SECOND_TEXT_COLOR)
        .attr('transform', 'scale(1.1)')
        .datum({ endAngle: -Math.PI / 2 });

      this.bestValue = this.bestGroup.append('text')
        .text(0)
        .attr('class', 'hint-value animate')
        .attr('text-anchor', 'left')
        .attr('val', chData.bestFull !== undefined ? chData.bestFull : +chData.targetMax)
        .attr('val-str', chData.best !== undefined ? chData.best : chData.targetMax)
        .attr('prop', chData.bestFull >= 0 ? 'best' : 'target-max')
        .datum({ endAngle: -Math.PI / 2 });
    }
  }

  private drawChartBaseData() {
    const chData = this._chartData.getValue();
    this.baseGroup = this.svg.append('g')
      .attr('group-name', 'base')
      .datum({ endAngle: -Math.PI / 2 });

    this.baseGroup.append('path')
      .attr('d', MARK_SVG_PATH)
      .attr('fill', SECOND_TEXT_COLOR)
      .attr('transform', 'scale(1.1)')
      .datum({ endAngle: -Math.PI / 2 });

    if (!this.hideBaseValue) {
      this.baseValue = this.baseGroup.append('text')
        .text(0)
        .attr('class', 'hint-value animate')
        .attr('text-anchor', 'left')
        .attr('val', chData.baseFull !== undefined ? chData.baseFull : chData.target || +chData.targetMin)
        .attr('val-str', chData.base !== undefined ? chData.base : chData.target || chData.targetMin)
        .attr('prop', chData.baseFull !== undefined ? 'base' : chData.chartType === ChartType.RidesPerWeek ? 'targetOne' : 'target-min')
        .datum({ endAngle: -Math.PI / 2 });
    }
  }

  private drawChartTargetRangeData() {
    this.targetGroup = this.svg.append('g')
      .attr('group-name', 'targetRange')
      .datum({ endAngle: 0 });

    this.targetRange = this.targetGroup.append('text')
      .attr('class', 'hint-value animate')
      .attr('text-anchor', 'left')
      .attr('prop', 'targetRange')
      .datum({ endAngle: 0 });
  }

  private updateBestValue(newAngle: number) {
    this.updateValueTemplate(this.bestGroup, this.bestValue, newAngle);
  }

  private updateBaseValue(newAngle: number) {
    this.updateValueTemplate(this.baseGroup, this.baseValue, newAngle);
  }

  private updateValueTemplate(group, value, newAngle: number) {
    if (value) {
      value.attr('arrowAngle', newAngle);
      value
        .transition()
        .duration(this.animationDuration)
        .call(GaugeChartComponent.textTween);
      value.transition().attrTween('transform', () => this.bestBaseValueTransform({ endAngle: newAngle }, newAngle, value));
    }
    if (group) {
      group
        .transition()
        .duration(this.animationDuration)
        .attrTween('transform', (d: any) => this.groupTransform(d, newAngle));
    }
  }

  private textBound(bestBaseGroupElement, type?: string) {
    let result = { width: 0, height: 0 };
    let styleFontSize = window.getComputedStyle(bestBaseGroupElement.node() as Element, null).getPropertyValue('font-size');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!styleFontSize)
      styleFontSize = `${this.fontSize}px`;
    context.font = `${styleFontSize} Roobert`;
    if (styleFontSize.endsWith('px')) {
      styleFontSize = styleFontSize.substr(0, styleFontSize.length - 2);
    }
    let fontSize = parseFloat(styleFontSize);

    type = type || bestBaseGroupElement.node().getAttribute('prop');
    switch (type) {
      case 'best':
        result.width = Math.max(result.width, Math.ceil(context.measureText('Best').width));
        result.width = Math.max(result.width, Math.ceil(context.measureText(bestBaseGroupElement.attr('val-str')).width));
        result.height = 2 * fontSize;
        break;
      case 'base':
        result.width = Math.max(result.width, Math.ceil(context.measureText('Base').width));
        result.width = Math.max(result.width, Math.ceil(context.measureText(bestBaseGroupElement.attr('val-str')).width));
        result.height = 2 * fontSize;
        break;
      case 'targetOne':
        result.width = Math.max(result.width, Math.ceil(context.measureText('Target').width));
        result.width = Math.max(result.width, Math.ceil(context.measureText(bestBaseGroupElement.attr('val-str')).width));
        result.height = 2 * fontSize;
        break;
      case 'targetRange':
        result.width = Math.max(result.width, Math.ceil(context.measureText('Target').width));
        result.height = 2 * fontSize;
        break;
      case 'target-min':
      case 'target-max':
        result.width = Math.max(result.width, Math.ceil(context.measureText(bestBaseGroupElement.attr('val-str')).width));
        result.height = 1.4 * fontSize;
        break;
      default:
        result.height = 1.4 * fontSize;
    }

    return result;
  }

  private bestBaseOffsetX(bestBaseBound, newAngle: number): number {
    return -bestBaseBound.width / 2 + Math.sin(newAngle) * (bestBaseBound.width / 2 + this.arrowBound.length * 1.5);
  }

  private bestBaseOffsetY(bestBaseBound, newAngle: number): number {
    return -bestBaseBound.height - Math.cos(newAngle) * (bestBaseBound.height / 2 + this.arrowBound.length * 1.5);
  }

  private bestBaseValueTransform(d, newAngle: number, bestBaseElement) {
    const interpolateAngle = d3Interpolate.interpolateNumber(d.endAngle, newAngle);
    const bestBaseBound = this.textBound(bestBaseElement);

    const optimization = this.textPositionOptimization(bestBaseElement);

    return (t: number) => {
      const angleDegrees = GaugeChartComponent.calcAngleDegrees(interpolateAngle(t));
      return `
            rotate(${180 - angleDegrees - 2})
            translate(${this.bestBaseOffsetX(bestBaseBound, newAngle)} ${this.bestBaseOffsetY(bestBaseBound, newAngle)})
            ${optimization}
          `;
    };
  }

  private arrowIndent() {
    return this.size;
  };

  private groupTransform(d, angle: number) {
    const arrowIndent = this.arrowIndent();
    const newAngle = angle + Math.asin(this.arrowBound.height / (arrowIndent + this.arrowBound.length)) / 2;
    const interpolate = d3Interpolate.interpolateNumber(d.endAngle, newAngle);

    return (t: number) => {
      const angleDegrees = GaugeChartComponent.calcAngleDegrees(interpolate(t));
      return `
          translate(${this.size}, ${this.size})
          rotate(${angleDegrees + 180})
          translate(0 ${arrowIndent})
        `;
    };
  }

  private updateTargetRangeValue(angle: number, baseAngle: number, bestAngle: number) {
    let newAngle = angle;
    if (this.targetRange) {
      const textNoteBound = this.textBound(this.baseValue, 'targetRange');
      newAngle = (bestAngle + baseAngle) / 2;
      this.updateBaseValue(baseAngle);
      this.updateBestValue(bestAngle);
      this.targetRange
        .transition()
        .duration(this.animationDuration)
        .attrTween('transform', (d) => {
          const interpolateAngle = d3Interpolate.interpolateNumber(d.endAngle, newAngle);

          const offsetX = this.bestBaseOffsetX(textNoteBound, newAngle) + textNoteBound.width / 3;
          const offsetY = this.bestBaseOffsetY(textNoteBound, newAngle) * 0.85;

          return (t: number) => {
            const angleDegrees = GaugeChartComponent.calcAngleDegrees(interpolateAngle(t));

            return `
            rotate(${180 - angleDegrees})
            translate(${offsetX} ${offsetY})
          `;
          };
        })
        .call(GaugeChartComponent.textTween);
    }

    if (this.targetGroup) {

      this.targetGroup
        .transition()
        .duration(this.animationDuration)
        .attrTween('transform', (d) => this.groupTransform(d, newAngle));
    }
  }

  private drawStar(centerX: number, centerY: number): void {
    const angle = Math.PI / 5;
    const points = [];
    let radius: number;

    for (let i = 0; i <= 10; i++) {
      radius = i & 1 ? this.innerRadiusStar : this.outerRadiusStar;
      points.push(centerX + radius * Math.sin(i * angle));
      points.push(centerY - radius * Math.cos(i * angle));
    }

    const strokeWidth = this.outerRadiusStar > 20 ? '2px' : '1px';
    const starsGroup = this.svg.append('g')
      .attr('transform', 'translate(0 0)');

    starsGroup
      .append('polygon')
      .attr('points', points)
      .attr('fill', 'transparent')
      .attr('stroke', MAIN_TEXT_COLOR)
      .attr('stroke-width', strokeWidth);
  }

  private drawStars(starCount: number): void {
    const mainStarY = this.size * 0.3;
    const secondStarY = this.size * 0.35;

    switch (starCount) {
      case 1:
        this.drawStar(this.size, mainStarY);
        break;
      case 2:
        this.drawStar(this.size - 1.4 * this.outerRadiusStar, secondStarY);
        this.drawStar(this.size + 1.4 * this.outerRadiusStar, secondStarY);
        break;
      case 3:
        this.drawStar(this.size, mainStarY);
        this.drawStar(this.size - 2 * this.outerRadiusStar, secondStarY);
        this.drawStar(this.size + 2 * this.outerRadiusStar, secondStarY);
        break;
    }
  }

  private textPositionOptimization(curElement): string {
    // let textBlocks: {element, arrowAngle}[] = [];
    let minElement: { element, arrowAngle, bound };
    let maxElement: { element, arrowAngle, bound };
    // Fill in the minimum and maximum values
    if (this.bestValue) {
      const arrowAngle = parseFloat(this.bestValue.attr('arrowAngle'));
      const bound = this.textBound(this.bestValue, this.bestValue.attr('prop'));
      minElement = { element: this.bestValue, arrowAngle, bound };
    }
    if (this.baseValue) {
      const arrowAngle = parseFloat(this.baseValue.attr('arrowAngle'));
      const bound = this.textBound(this.baseValue, this.baseValue.attr('prop'));
      const newElement = { element: this.baseValue, arrowAngle, bound };
      if (!!minElement) {
        if (minElement.arrowAngle < newElement.arrowAngle) {
          maxElement = newElement;
        } else {
          maxElement = minElement;
          minElement = newElement;
        }
      } else {
        minElement = newElement;
      }
    }
    // Bottom line
    const isMin = curElement.attr('prop') === minElement.element.attr('prop');
    const radius = this.arrowIndent() + this.arrowBound.length + this.fontSize;
    let bottomMergeX = 0;
    let bottomMergeY = 0;
    if (Math.sin(Math.abs(Math.PI / 2 - minElement.arrowAngle)) * radius < minElement.bound.height / 2) {
      bottomMergeY = (Math.sin(Math.abs(Math.PI / 2 - minElement.arrowAngle)) * radius - minElement.bound.height / 2) / 2;
      bottomMergeX = ((1 - Math.cos(Math.PI / 2 - Math.abs(minElement.arrowAngle))) * radius) / 2;
      if (isMin && minElement.arrowAngle < 0) {
        return `translate(${bottomMergeX}, ${bottomMergeY})`;
      }
    }

    if (minElement && maxElement) {
      const minAngleLimit = -Math.PI / 2 + Math.asin(minElement.bound.height / 2 / radius);
      if (minElement.arrowAngle < minAngleLimit) {
        minElement.arrowAngle = minAngleLimit;
      }
      const minAngle = Math.max(minElement.arrowAngle, minAngleLimit);
      const sideSign = (minAngle < 0) ? -1 : 1;
      const maxAngle = maxElement.arrowAngle;
      const maxX = this.bestBaseOffsetX(maxElement.bound, maxAngle) + radius * Math.sin(maxAngle);
      const minX = this.bestBaseOffsetX(minElement.bound, minAngle) + radius * Math.sin(minAngle);
      const crossX = maxX - minX;
      const maxY = this.bestBaseOffsetY(maxElement.bound, maxAngle) + radius * Math.cos(maxAngle);
      const minY = this.bestBaseOffsetY(minElement.bound, minAngle) + radius * Math.cos(minAngle);
      const crossY = minY - maxY;
      // Let's check the intersection of centers

      if (crossX < (minElement.bound.width + maxElement.bound.width) / 2 && sideSign * crossY < (minElement.bound.height + maxElement.bound.height) / 2) {
        // Desired offset between centers when superimposed.
        let diffY = ((maxElement.bound.height + minElement.bound.height) / 2 - crossY);
        let diffX = ((maxElement.bound.width + minElement.bound.width) / 2 - crossX);

        // Calculation of angles in X and Y when the overlap disappears.
        const lenMaxY = (-maxY - sideSign * diffY);
        let newMaxAngleY: number;
        if (lenMaxY > 0) {
          newMaxAngleY = sideSign * Math.acos(lenMaxY /
            (radius + Math.abs(this.bestBaseOffsetY(maxElement.bound, maxAngle))));
        } else {
          newMaxAngleY = Math.PI - sideSign * Math.acos(-lenMaxY /
            (radius + Math.abs(this.bestBaseOffsetY(maxElement.bound, maxAngle))));
        }
        const lenMinY = (-minY + sideSign * diffY);
        let newMinAngleY = sideSign * Math.acos(lenMinY /
          (radius + Math.abs(this.bestBaseOffsetY(maxElement.bound, minAngle))));


        let newMaxAngleX = Math.asin((maxX - this.bestBaseOffsetX(maxElement.bound, maxAngle) + diffX) /
          (radius + Math.abs(this.bestBaseOffsetX(maxElement.bound, maxAngle))));

        let newMinAngleX = Math.asin((minX - this.bestBaseOffsetX(minElement.bound, maxAngle) - diffX) /
          (radius + Math.abs(this.bestBaseOffsetX(maxElement.bound, minAngle))));

        if (newMinAngleY < minAngleLimit) {
          newMaxAngleY = sideSign * Math.acos((minElement.bound.height + (maxElement.bound.height + minElement.bound.height) / 2 + this.fontSize) /
            (radius - this.fontSize / 2 + Math.abs(this.bestBaseOffsetY(maxElement.bound, maxAngle))));
          newMinAngleY = minAngleLimit;
        }

        if (newMaxAngleY > Math.abs(minAngleLimit)) {
          newMinAngleY = Math.acos((maxElement.bound.height + (maxElement.bound.height + minElement.bound.height) / 2 + this.fontSize) /
            (radius - this.fontSize / 2 + Math.abs(this.bestBaseOffsetY(minElement.bound, minAngle))));
          newMaxAngleY = Math.abs(minAngleLimit);
        }

        let newAngleMax: number;
        let newAngleMin: number;

        // Let's choose the smallest deviation between horizontal and vertical displacement
        if (!!newMaxAngleX && !!newMaxAngleY) {
          if (Math.abs(newMaxAngleX - maxAngle) < Math.abs(newMaxAngleY - maxAngle)) {
            newAngleMax = newMaxAngleX;
            newAngleMin = newMinAngleX;
          } else {
            newAngleMax = newMaxAngleY;
            newAngleMin = newMinAngleY;
          }
        } else if (!!newMaxAngleY) {
          newAngleMax = newMaxAngleY;
          newAngleMin = newMinAngleY;
        } else {
          newAngleMax = newMaxAngleX;
          newAngleMin = newMinAngleX;
        }

        let newAngle: number;
        let oldY: number;
        let oldX: number;
        let bound: number;
        if (isMin) {
          newAngle = newAngleMin;
          oldY = minY;
          oldX = minX;
          bound = minElement.bound;
        } else {
          newAngle = newAngleMax;
          oldY = maxY;
          oldX = maxX;
          bound = maxElement.bound;
        }
        let translateY = oldY - (this.bestBaseOffsetY(bound, newAngle) + radius * Math.cos(newAngle));
        let translateX = ((this.bestBaseOffsetX(bound, newAngle) + radius * Math.sin(newAngle)) - oldX) / 2;
        if (isNaN(translateX) || isNaN(translateY))
          return '';
        return `translate(${translateX}, ${translateY})`;
      }
    }
    return '';
  }
}

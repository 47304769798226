// @ts-ignore
import packageInfo from 'package.json';
import {EnvironmentModel} from './environment.model';

export const environment: EnvironmentModel = {
  version: packageInfo.version,
  url: 'https://dev.carolbike.com',
  production: true,
  firebase: {
    apiKey: "AIzaSyDRJ-p-nmMMtl1BTt_J-PWgf_rioRqh7X4",
    authDomain: "carol-app-96317.firebaseapp.com",
    databaseURL: "https://carol-app-96317.firebaseio.com",
    projectId: "carol-app-96317",
    storageBucket: "carol-app-96317.appspot.com",
    messagingSenderId: "304133723685",
    appId: "1:304133723685:web:125db81444ac49e6c291cc"
  },
  chargebeeProfile: 'carolbike-test'
};

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import {LINE_BACKGROUND_COLOR, MAIN_LINE_COLOR, PeersChartModel} from '@carol-nx/data';
import * as d3Select from 'd3-selection';
import 'd3-transition';

@Component({
  selector: 'carol-nx-simple-line-chart',
  templateUrl: './simple-line-chart.component.html',
  styleUrls: ['./simple-line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleLineChartComponent implements AfterViewInit {

  @Input()
  public readonly chartData: PeersChartModel = {
    vs: 100,
    vsFill: 0,
    title: '',
    color: ''
  };
  @ViewChild('holder', { static: true }) protected svgHolder: ElementRef;
  @ViewChild('scale', { static: true }) protected progressBar: ElementRef;
  @Input()
  public readonly total = 100;
  @Input()
  public readonly fullWidth: boolean = true;
  @Input()
  public readonly showVsFill: boolean = false;
  @Input()
  public barHeightUnit: string = '6px';
  @Input()
  public barWidth: number;
  @Input()
  public vsPeers: boolean;
  @Input()
  public resizeInitAfter: number;

  private svg: any;
  private contentHeight: number;
  private width: number;
  private fontSize = 14;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  onResize() {
    if (window) {
      const {width} = this.svgHolder.nativeElement.getBoundingClientRect();
      if (width === 0)
        return;
      this.changeDetectorRef.detach();
      this.drawProgressChartElements();
    }
  }

  get barHeight(): number {
    let value = parseFloat(this.barHeightUnit);
    if (this.barHeightUnit.endsWith('em')) {
      return value * this.fontSize;
    } else {
      return value;
    }
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detach();
    const element = this.progressBar.nativeElement;
    this.svg = d3Select.select(element);
    // microtask to get access to the element
    this.drawProgressChartElements();
  }

  private initChart() {

    const element = this.progressBar.nativeElement;
    element.innerHTML = '';
    const elementSvg = this.svgHolder.nativeElement as Element;
    const styleFontSize = window.getComputedStyle(elementSvg, null).getPropertyValue('font-size');
    this.fontSize = parseFloat(styleFontSize);
    if (!this.fontSize) {
      this.fontSize = 14;
    }

    const { width, height } = this.svgHolder.nativeElement.getBoundingClientRect();
    const neededWidth = this.fullWidth ? width : (55 * (width + 72) / 100);
    this.contentHeight = height;
    if (this.barWidth)
      this.width = this.barWidth;
    else
      this.width = neededWidth;
  }

  private drawProgressChartElements() {
    this.initChart();
    const backgroundFill = LINE_BACKGROUND_COLOR;
    const height = this.barHeight;
    let yPos = this.contentHeight - this.barHeight * 4;
    const xPos = 0;

    this.svg.append('text')
      .text(this.chartData?.title || '')
      .attr('class', 'title')
      .attr('y', yPos)
      .attr('x', xPos);

    if (this.vsPeers) {
      this.svg.attr('class', 'vs-peers');
    }

    if (this.showVsFill) {
      this.svg.append('text')
        .text(this.chartData.vsFill || 0)
        .attr('class', 'value')
        .attr('y', yPos)
        .attr('text-anchor', 'end')
        .attr('x', this.width);
    }

    this.svg.append('rect')
      .attr('class', 'bg-rect')
      .attr('fill', backgroundFill)
      .attr('height', height)
      .attr('width', this.width)
      .attr('x', 0)
      .attr('y', this.contentHeight - this.barHeight);

    const progress = this.svg.append('rect')
      .attr('class', 'progress-rect')
      .attr('fill', this.chartData?.color || MAIN_LINE_COLOR)
      .attr('height', height)
      .attr('width', 0)
      .attr('x', 0)
      .attr('y', this.contentHeight - this.barHeight);


    if (this.chartData?.vsFill > 0) {
      const progressTotalPercentage = this.chartData?.vsFill > this.total ? this.total : this.chartData?.vsFill;
      progress.attr('width', progressTotalPercentage / this.total * this.width);
    }

    this.changeDetectorRef.detectChanges();
  }

}
